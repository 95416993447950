<template>
 <div>
  <div class="footer">
      <div class="container">
        <div class="logofooter">
            <img src="https://lendarc.us/plantillav2/images/lendarc-logo.png" alt="credix-logo-footer">
            <div class="redes-sociales">
              
            </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="datos-footer">
              <div class="ubicaciones">
                <p style="font-weight:600">#2222 Ponce de León Boulevard, Suite 300, Miami, FL, 33134</p>
                <p><a href="mailto:contact@lendarc.us">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                  </svg>
                  
                  <span> contact@lendarc.us</span>
                  </a> </p>
                <p>
                  
                  <a href="tel:+1 (305) 788 0155"> 
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19.1,8.7C20.9,10.5 20.9,13.3 19.1,15.2L20.1,16.2C22.6,13.9 22.6,10.1 20.1,7.7L19.1,8.7M18,9.8L17,10.8C17.5,11.5 17.5,12.4 17,13.1L18,14.1C19.2,12.9 19.2,11.1 18,9.8M14,1H4A2,2 0 0,0 2,3V21A2,2 0 0,0 4,23H14A2,2 0 0,0 16,21V3A2,2 0 0,0 14,1M14,20H4V4H14V20Z" />
                    </svg>
                    <span>
                       +1 (305) 788 0155 
                    </span>
                    </a> </p>
              </div>
            </div>
          </div>
            <div class="col-md-2">
                <div class="contenido-footer" >
              </div>
            </div>
            <div class="col-md-2">
              <div class="contenido-footer">
                  <div class="item-footer"><h4>Solutions</h4></div> 
                  <div class="item-footer"><router-link to="/credit-line">Credit Line</router-link> </div>
                  <div class="item-footer"><router-link to="/revolving-credit-line">Revolving Credit Line</router-link> </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="contenido-footer">
                  <div class="item-footer"><h4>Contact</h4></div> 
                  <div class="item-footer"><router-link to="/privacy-policy">Privacy Policy</router-link> </div>
                  <div class="item-footer"><router-link to="/faqs">FAQ's</router-link> </div>
              </div>
            </div>
            
        </div>
      </div>
  </div>
  <div class="footer2">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center">© Copyright LendArc 2022. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="socialContact" id="social-share">
     
      <div class="itemCn TelContac" tooltip="telefono" > 
        <a href="tel:+1(305)7880155" target="_blank">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19.1,8.7C20.9,10.5 20.9,13.3 19.1,15.2L20.1,16.2C22.6,13.9 22.6,10.1 20.1,7.7L19.1,8.7M18,9.8L17,10.8C17.5,11.5 17.5,12.4 17,13.1L18,14.1C19.2,12.9 19.2,11.1 18,9.8M14,1H4A2,2 0 0,0 2,3V21A2,2 0 0,0 4,23H14A2,2 0 0,0 16,21V3A2,2 0 0,0 14,1M14,20H4V4H14V20Z" />
          </svg>
        </a>
      </div>
      <div class="itemCn whatsContac"  tooltip="whatsapp" >
        <a href="https://wa.me/+5215535107320" target="_blank">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
          </svg>
        </a>
      </div>
       <div class="itemCn" tooltip="share">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.08 14.82 15.69 14.9 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.58C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5M12 3V13L15 10H21V3H12Z" />
        </svg>
      </div>
  </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="css">

 .footer {
  background-color: #171f2a;
  padding: 60px 0;
  color: #fff;
}
.footer .logofooter {
  display: flex;
  justify-content: space-between;
}
.footer .logofooter img {
  height: 80px;
}
.footer .logofooter .redes-sociales {
  display: flex;
}
.footer .logofooter .redes-sociales a {
  color: #fff;
  margin: 0 5px;
}
.footer .datos-footer .ubicaciones {
  margin-top: 2rem;
}
.footer  a{
  text-decoration: none;
  color:white
}
.footer .container {
  width: 1170px;
}
.footer .contenido-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.footer .contenido-footer .item-footer {
  margin: 5px 0;
}
.footer .contenido-footer .item-footer h4 {
  letter-spacing: 1.8;
  font-weight: lighter;
}
.footer .contenido-footer .item-footer a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 0.5px;
}
.footer2 {
  background-color: #0e141d;
  padding: 3rem 0;
  color: #fff;
  font-size: 10px;
}
.footer2 .reconocimientos {
  display: flex;
  justify-content: flex-end;
}
.footer2 .reconocimientos img {
  height: 35px;
  margin: 0 2px;
}
@media only screen and (max-width: 768px) {
  .footer .container {
    width: 100%;
  }
  .footer .logofooter {
    align-items: center;
  }
}

.socialContact{
  bottom: 5%;
  position: fixed;
  margin: 1em;
  left: 0;
}
.itemCn{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 20px auto 0;
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18), 0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.itemCn a{
  color: #006699;
}
.itemCn >  svg {
  font-size: 38px;
  line-height: 60px;
  color:#006699;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
}
.itemCn:active,
.itemCn:focus,
.itemCn:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
}
.itemCn:not(:last-child) {
  width: 60px;
  height: 60px;
  margin: 20px auto 0;
  opacity: 0;
}
.itemCn:not(:last-child) > svg {
  font-size: 25px;
  line-height: 60px;
  transition: all .3s ease-in-out;
}
.socialContact:hover .itemCn:not(:last-child) {
  opacity: 1;
  width: 60px;
  height: 60px;
  margin: 15px auto 0;
}
.itemCn:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}
.itemCn:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 20ms;
  transition-delay: 20ms;
}
.itemCn:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 40ms;
  transition-delay: 40ms;
}
.itemCn:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 60ms;
  transition-delay: 60ms;
}
.itemCn:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 80ms;
  transition-delay: 80ms;
}
.itemCn:not(:last-child):nth-last-child(6) {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}
[tooltip]:hover:before,
[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
 
</style>