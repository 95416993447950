<template>
 
  <section class="faqs">
      <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Frequent <span>Questions</span> </h1>
            </div>
          </div>
          <div class="accordion mt-4">
              <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">What is Lendarc?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                   We are a Company specialized in Real Estate Backed Loans, providing a more flexible, faster alternative to traditional Banking.
                   <br><br>
We have support from a solid international holding, and the financial experience of over 15 years with our Credix subsidiary in Mexico
                </p>
                 </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Lendarc is a bank?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                  Lendarc is a hard money lending company, not a bank. 
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Is Lendarc reliable?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    Yes, we strive daily to meet high quality standards to be confident with our team and customers.
                </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">If my credit score is bad, can I apply for a loan?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <p>
                        Yes, in Lendarc the credit score is NOT a determinant factor to obtain a credit line with us.
                    </p>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">How to apply?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <p>
                        You can start the path to recive your loan, by filling out and completing our Pre approval application and submitting to us at your earliest convenience.
<br>through internet accessing our website www.lendarc.us <br>
All provisions require notice 3 business days (as indicated in your contract).
<br><br>
                    </p>    
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">If I do not have real estate, can I apply for a loan?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <p>
                      No, we currently do not make loans without an asset to back it up. Our credits are guaranteed with real estate. By offering the asset as collateral, we can offer better amounts, competitive interest rates and more flexible terms.
                    </p>
                </template>
            </Pregunta>
           
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Where are we located?</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    We currently have offices in:
                </p>
                <p>
                    <b>#2222 PONCE DE LEON BOULEVARD, SUITE 300, MIAMI, FLORIDA, 33134.</b> <br><br>
                    <b>T. +1 (305) 788 0155</b>
                </p>
                </template>
            </Pregunta>
        </div>
      </div>
  </section>
</template>

<script>
import Pregunta from "@/components/otros/Accordion";
export default {
components:{
    Pregunta
},
 methods:{
        
    }

}
</script>

<style lang="css">
    .faqs{
        margin: 4rem 0;
    }
</style>