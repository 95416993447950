import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Simple from '../views/credit-line.vue'
import Revolvente from '../views/revolving-line.vue'
import About from '../views/about.vue'
import Privacy from '../views/privacy-policy.vue'
import FAQ from '../views/faqs.vue'
import Control from '../views/login/Control.vue'
import Login from '../views/login/Login.vue'
import Brokers from '../views/brokers.vue'
import Apply from '../views/apply.vue'
import Complete from '../views/complete.vue'
import NoComplete from '../views/nocomplete.vue'
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    component:Home,
    children:[
      {
        path: '/',
        component: Index
      },
      {
        path:'/about-us',
        component:About
      },
      {
        path:'/credit-line',
        component:Simple
      },
      {
        path:'/revolving-credit-line',
        component:Revolvente
      },
      {
        path:'/brokers',
        component:Brokers
      },
      {
        path:'/contact-us',
        component:Revolvente
      },
      {
        path:'/privacy-policy',
        component:Privacy
      },
      {
        path:'/faqs',
        component:FAQ
      },
      {
        path:'/apply-now',
        component:Apply
      },
      {
        path:'/complete',
        component:Complete
      },
      {
        path:'/no-complete',
        component:NoComplete
      },
    ]
  },
  {
    path:'/login',
    component: Control,
    children:[
      {
        path:"/login",
        component: Login
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
 } 
})

export default router
