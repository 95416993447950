<template>
<div>
<section class="header2" id="head2" v-scrollanimation>
    <div class="imgLogo">
     <a href="/"> <img src="https://lendarc.us/assets/images/logolendarc.png" alt="Logo Credix" width="150"></a>
    </div> 
  </section>
  <section class="login-form">
    <div class="sec-rig"  v-scrollanimation>
        <div class="container-form" v-if="!iniciando">
          <form >
            <h1>Welcome!</h1>
          <div class="grupo-input">
            <p>Username </p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
                </svg>  
              </div>
              <input type="text" v-model="usr">
            </div>
          </div>
          <div class="grupo-input">
            <p>Password</p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5.8 10C5.4 8.8 4.3 8 3 8C1.3 8 0 9.3 0 11S1.3 14 3 14C4.3 14 5.4 13.2 5.8 12H7V14H9V12H11V10H5.8M3 12C2.4 12 2 11.6 2 11S2.4 10 3 10 4 10.4 4 11 3.6 12 3 12M16 4C13.8 4 12 5.8 12 8S13.8 12 16 12 20 10.2 20 8 18.2 4 16 4M16 10.1C14.8 10.1 13.9 9.2 13.9 8C13.9 6.8 14.8 5.9 16 5.9C17.2 5.9 18.1 6.8 18.1 8S17.2 10.1 16 10.1M16 13C13.3 13 8 14.3 8 17V20H24V17C24 14.3 18.7 13 16 13M22.1 18.1H9.9V17C9.9 16.4 13 14.9 16 14.9C19 14.9 22.1 16.4 22.1 17V18.1Z" />
                </svg> 
              </div>
              <input type="password" v-model="pass" >
            </div>
          </div>
          <div class="errores" v-if="errorlogin">
            <p>Sorry, the username or password is incorrect, please try again.</p>
          </div>
          <div class="errores" v-if="errores.length">
            <ul>
              <li v-for="(error,index) in errores" :key="index">{{ error }}</li>
            </ul>
          </div>
          
          <div class="grupo-input">
            <button class="btn-credix" @click="login()" type="button">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M10 13C7.33 13 2 14.33 2 17V20H13.09C13.07 19.86 13.05 19.73 13.04 19.59C13 19.4 13 19.2 13 19C13 18.69 13.03 18.39 13.08 18.1C13.21 17.21 13.54 16.38 14 15.66C14.21 15.38 14.42 15.12 14.65 14.88L14.67 14.85C14.9 14.61 15.16 14.39 15.43 14.19C14.76 13.88 14 13.64 13.26 13.45C12.07 13.15 10.89 13 10 13M11.05 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.68 14.9 11.36 15 12 15.11C11.5 16 11.18 17 11.05 18.1M10 12C12.21 12 14 10.21 14 8S12.21 4 10 4 6 5.79 6 8 7.79 12 10 12M10 6C11.11 6 12 6.9 12 8S11.11 10 10 10 8 9.11 8 8 8.9 6 10 6M20 22V20H16V18H20V16L23 19L20 22Z" />
                </svg>
             &nbsp; Sign In</button>
          </div>
          </form>
          
        </div>
        <div class="container-form" v-if="iniciando">
          <Loading texto="espere un momento"/>
        </div>
      </div>
      <div class="sec-left">
       
      </div>
      
  </section>
</div>

</template>

<script>
import axios from "axios";
import Loading from '@/components/Puntos.vue' 
export default {
  
  methods:{
    
    login(){
      this.errores = []
      if(this.usr.length < 4 ){
        this.errores.push("Username is invalid!")
      }
      if(this.pass == ""){
        this.errores.push("Please write a password!")
      }
      if(this.errores.length == 0){
        var formLogin = new FormData()
        formLogin.append("strusr",this.usr)
        formLogin.append("strpw",this.pass)
        formLogin.append("key",this.getKey())
        
        axios.post("api/front/login.php", formLogin)
        .then((result) => {
          //console.log(result)
          if(result.data.code === 1 ){
            this.iniciando = true
            setTimeout(() => {
              let userCredixLogin = {
              name : result.data.name,
              url: result.data.url,
              usr: result.data.usr
            }
            const regApp = btoa(JSON.stringify(userCredixLogin))
            localStorage.setItem("regApp",regApp)
            location.href = result.data.url
            }, 2000);
            
          }else{
            this.errorlogin = true
          }
          
        })
        .catch(err =>console.log(err));
      }
      
    },
    getKey(){
      var crypto = require('crypto')
      var shasum = crypto.createHash('sha1')
      var dateObj = new Date();
      var month = String(dateObj.getMonth() + 1).padStart(2, '0');
      var day = String(dateObj.getDate()).padStart(2, '0');
      var year = dateObj.getUTCFullYear();
      var completeKey = "credix"+ year + month  + day;
      shasum.update(completeKey)
      let key = shasum.digest('hex')
      return key
    },
    haysesion(){
      axios.post('api/front/login.php')
      .then((res)=>{
        console.log(res)
      })
      .catch(err=>console.log(err))
    }
  },
  mounted(){
    this.haysesion()
  },
  components:{
    Loading
  },
  data:()=>({
    usr:"",
    pass:"",
    retorno:[],
    errores:[],
    errorlogin:false,
    iniciando:false
  })
}
</script>

<style lang="css" scoped>
.errores{
  background-color: #d0022150;
  color: #d00222;
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.errores p{
  margin: 0;
  font-size: 10pt;
}
.errores ul{
  list-style: none;
  margin-bottom: 0rem;
}
.errores ul li{
  font-size: 9pt;
}
.header2#head2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 35%;
  padding: 0.5rem 1rem;
}
.header2#head2 .imgLogo {
  padding-left: 2rem;
}
.container-form {
  width: 400px;
  height: unset!important;
}
.container-form .input {
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
}
.container-form input {
  margin-left: 20px;
  width: 100%;
  border: none;
}
.container-form input:focus-visible {
  border: none;
  outline: none;
}
.login-form {
  width: 100%;
  background: #006699;
  height: 100vh;
  display: flex;
}
.login-form .sec-left {
  width: 63%;
  height: 100%;
  color: #fff;
  background-image: url('~@/assets/estrella.webp');
  background-size: cover;
  background-position: center;
  background-color: #006699;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
}
.login-form .sec-left .itemben {
  display: flex;
  margin: 1rem 0;
}
.login-form .sec-left .itemben h5 {
  margin-left: 20px;
  font-weight: lighter;
}
.login-form .sec-rig {
  width: 38%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form .sec-rig h1 {
  color: #006699;
  font-weight: 300;
}
.grupo-input {
  margin-bottom: 1rem;
}
.grupo-input a {
  text-decoration: none;
  color: #006699;
}
.grupo-input p {
  margin-bottom: 5px;
}
.grupo-input .forget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen 
  and (min-width: 768px)
  and (max-width: 1100px){
.login-form .sec-rig {
  width: 90%;
}
}
@media only screen and (max-width: 768px) {
  .header2#head2 {
    width: 100%;
    padding: 1rem 1rem;
    background: none;
    z-index: 99;
  }
  .header2#head2 .imgLogo {
    text-align: center;
    padding-left: 0rem;
  }
  .login-form {
    height: 100%;
    background-size: cover;
    background-image: url('~@/assets/estrella.webp');
  }
  .login-form .sec-left {
    display: none;
  }
  .login-form .sec-rig {
    width: 90%;
    height: 100vh;
    margin: auto auto;
    padding: 0 2rem;
  }
  .login-form .sec-rig .container-form {
    display: block;
  }
  .login-form .sec-rig .container-form .formulario {
    padding: 1.3rem 1.5rem;
    height: 100%;
    display: block;
  }
  .login-form .sec-rig .container-form .formulario .pasos {
    display: block;
    margin-bottom: 20rem;
    height: auto;
  }
  .login-form .sec-rig .container-form .formulario .pasos .paso.activo {
    height: 100%;
  }
  .login-form .sec-rig .container-form .footerform {
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    display: block;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .login-form .sec-rig .container-form .footerform .step {
    float: left;
    text-align: center;
    margin: 0;
    width: 25%;
  }
}

</style>