<template>
  <section class="aviso">
      <div class="container">   
        <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Privacy  <span>Policy</span> </h1>
            </div>
          </div>
          <h6>Update: at June 2022</h6>

                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-lg-12">
    <br>
<p style="color:#000; font-size:18px; text-align:justify;">

												<span style="color:#47d4ff; font-weight:700"></span>

This <strong>Privacy Policy</strong> has been compiled to better serve those who are concerned with how their ‘Personally identifiable information’ (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. Our Privacy Officer is Fidel Díaz Barriga Silva.
<br><br>
By using our website, you are consenting to the use of information and agreeing to these guidelines and the other <strong>Lendarc</strong> policies described on our website.
<br><br>

<strong>Lendarc</strong> does not share your personal information with outside companies or persons. <br><br>

<strong>What personal information do we collect from the people that visit our blog, website or app?</strong><br><br>
When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, social security number or other details to help you with your experience.<br><br>

We collect information from you when you request information, apply for a loan, subscribe to a newsletter, respond to a survey, or enter information on our site.<br><br>
<strong>How do we use your information?</strong><br><br>	
We may use the information we collect from you when you register, request information, apply for a loan, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:<br>
•	To personalize user’s experience and to allow us to deliver the type of content and product offerings in which you are most interested.<br>
•	To allow us to better service you in responding to your customer service requests.<br>
•	To quickly process your transactions.<br>
•	To send periodic emails regarding your order or other products and services.<br><br>

<strong>How do we protect visitor information?</strong><br><br>

Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.<br><br>

We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.<br><br>

<strong>All transactions are processed through a gateway provider and are not stored or processed on our servers.
Do we use ‘cookies’?</strong><br><br>

Cookies: A ‘cookie‘ is a small piece of information that our website may provide to your browser while you are at our sites. The <strong>Lendarc</strong> website supplies your browser with cookies that contain a unique identifier used to better understand website usage in the aggregate and on an individual level so we know what areas of our site users prefer (e.g., based on the number of visits to those areas). This is done through a tracking utility that allows us, for example, to reconstruct activity from a session or by a user, for troubleshooting and issue resolution purposes. <strong>Lendarc</strong> may also employ service providers to help us collect and understand our website usage data.<br><br>

When periodic surveys are presented to website visitors, cookies are used to prevent issuing multiple invitations to the same individual.<br><br>
If you simply want to browse, you do not have to accept cookies from our site. Should you decide, however, that you would like to register and sign in to special areas of the website and you have modified your browser settings not to accept cookies, you will need to re-set your browser to accept the cookies that we send. Otherwise, you won't be able to participate in certain areas of the website. Most browsers are defaulted to accept and maintain cookies.<br><br>

If you wish to remove cookies provided by <strong>Lendarc</strong>, please check your browser's settings and Help section for instructions for removing cookies from your browser.<br><br>

Behavioral Advertising: Please be aware that we use third party advertising companies to collect non-personally identifiable information for online behavioral advertising to provide targeted display advertisements, likely to be of greater interest to you, through participating publishers and advertisers. Such information may include non-personally identifiable information (e.g., click stream information, browser type, time and date, subject of advertisements clicked or scrolled over) through the use of Cookies and other technology.
<br><br>

Social Media and Third-Party Website Security and Privacy Policies: <strong>Lendarc</strong> currently has presence on social media to connect with and market to the public, our existing and potential customers. Your activity on third-party websites is governed by the security and privacy policies of each third-party website.<br><br>

You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser’s Help menu to learn the correct way to modify your cookies.
If you disable cookies off, some features will be disabled that make your site experience more efficient and some of our services will not function properly. However, you can still place orders.<br><br>

<strong>Third Party Disclosure</strong><br><br>

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety.<br><br>

However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.<br><br>

<strong>Third party links</strong><br><br>

Occasionally, at our discretion, we may include or offer third party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.<br><br>

<strong>Google</strong><br><br>

Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en <br><br>

We have not enabled Google AdSense on our site but we may do so in the future.<br><br>

<strong>COPPA (Children Online Privacy Protection Act)</strong><br><br>

When it comes to the collection of personal information from children under 13, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.<br><br>

We do not specifically market to children under 13.<br><br>

<strong>Fair Information Practices</strong><br><br>

The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.<br><br>

In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:<br><br>

We will notify the users via email as soon as reasonable after discovery of the data breach. We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.<br><br>

<strong>CAN SPAM Act</strong><br><br>

The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. We collect your email address in order to:<br><br>
	
•	Send information, respond to inquiries, and/or other requests or questions<br>
•	Process orders and to send information and updates pertaining to orders<br>
•	Market to our mailing list or continue to send emails to our clients after the original transaction has occurred<br>
<br><br>
To be accordance with CANSPAM we agree to the following<br>
•	NOT use false, or misleading subjects or email addresses<br>
•	Identify the message as an advertisement in some reasonable way<br>
•	Include the physical address of our business or site headquarters<br>
•	Monitor third party email marketing services for compliance, if one is used<br>
•	Honor opt-out /unsubscribe requests quickly<br>
•	Allow users to unsubscribe by using the link at the bottom of each email<br><br>
If at any time you would like to unsubscribe from receiving future emails, you can email us and we will promptly remove you from ALL correspondence.<br><br>
If there are any questions regarding this Privacy Policy you may contact us using the information below.

</p>

                                            
                                         
                            </div>

                        </div>

                    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.aviso{
    margin:3rem 0;
}
p strong{
    color:#006699;
}
</style>