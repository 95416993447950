<template>
  <section class="form-broker">
      <div class="block-item "  :class="[(slide > 1 && slide !=1 ? 'despues':''),(slide == 1 ? 'activo':'antes')]" >
          <div class="block-item-content ">
            <h1>Join Us</h1>
            <h3>We are looking for alliances that allow us to continue growing and you can be part of this... Are you the right person for the job?</h3>
            <div class="d-flex">
                <button @click="siguiente(0)">Start</button>
            </div>
            
          </div>          
     </div>
     <div class="block-item "   :class="[(slide > 2 && slide !=2 ? 'despues':''),(slide == 2 ? 'activo':'antes')]">
          <div class="block-item-content ">
            <div class="pregunta">
                <div class="pregunta-titulo">
                    <span>1.-</span>
                    <span>What is you full name?</span>
                </div>
                <div class="pregunta-input">
                    <input type="text" v-model="mNombre">
                </div>
                <div class="errores" v-if="errores.nombre">
                  <small>Your name is invalid.</small>
                </div>
            </div>
            <div class="d-flex">
                <button @click="siguiente(1)">Siguiente</button>
            </div>
          </div>          
     </div>
     <div class="block-item "   :class="[(slide > 3 && slide !=3 ?  'despues':''),(slide == 3 ? 'activo':'antes')]">
          <div class="block-item-content ">
              <div class="textos">
                  <p> It's a pleasure to meet you <b>{{mNombre}}</b>, Thank you for taking the time to apply.. <br> Give us more information:</p>
              </div>
            <div class="pregunta">
                <div class="pregunta-titulo">
                    <span>2.-</span>
                    <span>what is your email?</span>
                </div>
                <div class="pregunta-input">
                    <input type="text" v-model="mCorreo">
                </div>
                <div class="errores" v-if="errores.correo">
                  <small>Your email is invalid.</small>
                </div>
            </div>
            <div class="pregunta">
                <div class="pregunta-titulo">
                    <span>3.-</span>
                    <span>What is your phone number?</span>
                </div>
                <div class="pregunta-input">
                    <input type="text" v-model="mTelefono">
                </div>
                <div class="errores" v-if="errores.tel">
                  <small>Your phone number is invalid.</small>
                </div>
            </div>
            <div class="d-flex">
                <button @click="siguiente(2)">Siguiente</button>
            </div>
          </div>          
     </div>
       <div class="block-item "   :class="[(slide > 4 && slide !=4 ?  'despues':''),(slide == 4 ? 'activo':'antes')]">
          <div class="block-item-content ">
              <div class="textos">
                  <p> <b>{{mNombre}}</b>, now upload your curriculum vitae in PDF format </p>
              </div>
            <div class="pregunta">
                <div class="pregunta-titulo">
                    <span>4.-</span>
                    <span>Upload your Curriculum Vitae</span>
                </div>
                <div class="pregunta-input file">
                    <label for="cv">Select file</label>
                    <input type="file" id="cv" accept=".pdf" @change="asignarCV">
                    <span>{{namefile}}</span>
                </div>
            </div>
            <div class="d-flex">
                <button @click="enviarDatos()">Send information</button>
            </div>
          </div>          
     </div>
     <div class="block-item "   :class="[(slide > 5 && slide !=5 ?  'despues':''),(slide == 5 ? 'activo':'antes')]">
          <div class="block-item-content ">
              <div class="textos">
                  <p> <b>{{mNombre}}</b>, gracias por aplicar.</p>
                  <p> Si tu perfil es la persona que estamos buscando, te lo haremos saber en cuanto antes.</p>
                  <p> </p>
              </div>
            
            
          </div>          
     </div>
     
  </section>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            slide:1,
            namefile:"Archivo no seleccionado...",
            mNombre:"",
            mCorreo:"",
            mTelefono:"",
            mCv:null,
            errores:{
              nombre:false,
              correo:false,
              tel:false
            }
        }
    },
    methods:{
        atras(){
            this.slide--;
        },
        siguiente(form){
            if(form == 0){
              this.slide++
            }
            if(form == 1){
              if( this.mNombre.length<5){
                this.errores.nombre = true
              }else{
                this.slide++;
              }
            }
            if(form == 2){
              if(this.mCorreo.length < 5 || this.mTelefono.length < 9){
                console.log("errores")
                if(this.mCorreo == ""){
                  this.errores.correo = true
                }else{
                  this.errores.correo = false
                }
                if(this.mTelefono.length < 9){
                  this.errores.tel = true
                }else{
                  this.errores.tel = false
                }
              }else{
                this.slide++
              }
            }
          },
          asignarCV(event){
            this.mCv = event.target.files[0]
            this.namefile = this.mCv.name 
            console.log(this.mCv)
          },
          enviarDatos(){
            var FormBroker  = new FormData();
            FormBroker.append("nombre",this.mNombre)
            FormBroker.append("correo",this.mCorreo)
            FormBroker.append("telefono",this.mTelefono)
            if(this.mCv.name != undefined){
                FormBroker.append("cv",this.mCv, this.mCv.name)
            }
            axios.post('https://credix.grupotron-gs.com/api/registro-broker',FormBroker)
            .then((res)=>{
              console.log(res)
            })
            .catch(err => console.log(err))
          }
       
    }
}
</script>

<style lang="css">
.errores{
  color: #d00524;
    font-weight: 600;
}
.pregunta {
  margin-top: 1.4rem;
}
.pregunta-titulo {
  margin: 0px;
  max-width: 100%;
  font-weight: unset;
  font-size: 18px;
  margin-bottom: 1rem;
  line-height: 32px;
}
.pregunta-input.file input {
  display: none;
}
.pregunta-input.file span {
  margin-left: 1rem;
}
.pregunta-input.file label {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: white;
  color: #006699;
  margin-top: 2rem;
  border-radius: 4px;
}
.pregunta-input input {
  display: block;
  width: 100%;
  font-family: inherit;
  color: #1a91a2;
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: #006699;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 9e ease 0s, box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(26, 145, 162, 0.3) 0px 1px;
  background-color: transparent !important;
}
.form-broker {
  background-image: url('~@/assets/largecredix.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.form-broker .block-item {
  width: 80%;
  margin: 0px auto;
  padding-left: 0px;
  padding-right: 0px;
  position: absolute;
  height: 100%;
  padding-top: 0px;
}
.form-broker .block-item .textos {
  margin: 0px;
  max-width: 100%;
  font-weight: unset;
  font-size: 24px;
  line-height: 32px;
  color: #535353;
}
.form-broker .block-item-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0 10rem;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}
.form-broker .block-item-content button {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: white;
  color: #006699;
  margin-top: 2rem;
  border-radius: 4px;
}
.form-broker .block-item.activo {
  position: absolute;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
}
.form-broker .block-item.antes {
  position: absolute;
  width: 100%;
  height: 0;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(100vh);
}
.form-broker .block-item.despues {
  position: absolute;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(-100vh);
}
@media only screen and (max-width: 768px) {
  .block-item-content {
    padding: 0 2rem !important;
  }
  .block-item-content h3{
     font-size: 1.2rem;
  }
  .pregunta-input input{
    font-size: 20px;
  }
  .form-broker .block-item .textos{
    font-size: 20px;
  }
  .form-broker{
    background-position-x: right;
  }
}

</style>